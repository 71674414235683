import React, { useState, useEffect } from "react"
import { Container, Row, Col, NavItem,Modal } from "react-bootstrap"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"
import axios from "axios"
import "./similarProperties.scss"
import SimilarPropertyImg from "../../../images/similar-property-img.png"
import propertyLabel from "../../../images/property-label.png"
import NoImage from "../../../images/no-image.png"
import Content from "../../Content/Content"
import getVideoId from "get-video-id"
import PlayVideo from "@Components/PlayVideo/PlayVideo"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import SearchResultsImagePopup from "@Components/SearchResults/SearchResultsImagePopup2"
import SearchResultsFloorplanPopup from "@Components/SearchResults/SearchResultsFloorplanPopup"



const SimilarPropertiesTeam = props => {
  //
  const [propItems, setPropItems] = useState([])
  const [isPlay, setIsPlay] = useState(false)

  const [videoid, setVideoId] = useState("")
  const [video_type, setVideoType] = useState("")
  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState(true)

  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""


  const playVideoHandler = (video_url, video_type) => {
    setIsPlay(true)
    setVideoId(video_url)
    setVideoType(video_type)
  } 

  function handleShow(video_url, video_type) {
    setShow(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  const getitems = async url => {
    try {
      const { data } = await axios.get(url,
        {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          },
        }
        )
      //
      setPropItems(data)
    } catch (error) {
      // console.error(error)
    }
  }
  useEffect(() => {
    //
    let area = props.slug ? props.slug : ""
    let saleurl =
      process.env.GATSBY_STRAPI_SRC +
      "/properties?crm_negotiator_email=" +
      props.Email +
      "&_limit=6&_sort=price:DESC&publish=true" // TODO: base URL supposed to be from .env
    if (area) {
      saleurl = saleurl + "&display_address_contains=" + area
    }

    getitems(saleurl)
  }, [])
  //

  // Slider settings
  var settings = {
    dots: false,
    nav: true,
    infinite: propItems.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: propItems.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: propItems.length > 1 ? true : false,
        },
      },
    ],
  }
  // Slider settings

  return (
    propItems.length > 0 && (
      <React.Fragment>
        <div className="relatednews-block opinion">
          <Container>
            <Row>
              <Col xs={8}>
                <h2>related properties</h2>
              </Col>
            </Row>

            <Slider className="opinion-list  overright" {...settings}>
              {propItems?.map((item, index) => {
                // Property detail url structure
                let uriStr = ""
                console.log(item,"iiiii")
                if (
                  item.search_type === "sales" &&
                  item.department === "residential"
                ) {
                  uriStr = `property-for-sale/`
                } else if (
                  item.search_type === "lettings" &&
                  item.department === "residential"
                ) {
                  uriStr = `property-to-rent/`
                } else if (
                  item.search_type === "sales" &&
                  item.department === "commercial"
                ) {
                  uriStr = `commercial-property-for-sale/`
                } else if (
                  item.search_type === "lettings" &&
                  item.department === "commercial"
                ) {
                  uriStr = `commercial-property-to-rent/`
                }
                else if(item.search_type==="lettings"&&item.department==="LE"){
                  uriStr = `property-to-rent/`
                }
                // Property detail url structure

                let videotourid =
                item?.video_tour?.url && getVideoId(item?.video_tour?.url)

              let virtualid =
                item?.virtual_url?.url && getVideoId(item?.virtual_url?.url)
              // ggfx images
              let processedImages = JSON.stringify({})
              if (item?.imagetransforms?.images_Transforms) {
                processedImages = item.imagetransforms.images_Transforms
              }

                return (
                  <div className="property-card">
                    <div className="property-card-wrapper">
                      <div className="property-img">
                      <Link
                                  to={`/${uriStr}${item?.slug}-${item?.id}/`}
                                >
                                 {item?.images && item?.images[0]?.url ? (
                                <ImageTransform
                                  imagesources={item?.images[0]?.url}
                                  renderer="srcSet"
                                  imagename="property.images.detail"
                                  attr={{
                                    alt:
                                      item?.display_address +
                                      " - DNG Estate Agents",
                                  }}
                                  imagetransformresult={processedImages}
                                  id={item.id}
                                  testparam={true}
                                />
                              ) : (
                                <img src={NoImage} alt="property" />
                              )}
                                   </Link>
                          <div className="property-wrapper">
                            <div className="left-block">
                            {item?.images && item?.images?.length > 0 && (
                                <SearchResultsImagePopup
                                  propertyId={item.id}
                                  propertyImage={item?.images}
                                />
                              )}
                              <div className="property-view">
                                
                              {item.video_tour?.url &&
                                  (videotourid?.service === "youtube" ? (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          playVideoHandler(
                                            item.video_tour?.url,
                                            "youtube"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          handleShow(
                                            item.video_tour?.url,
                                            "custom_video"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ))}

                              {item.virtual_url?.url &&
                                  (virtualid?.service === "youtube" ? (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          playVideoHandler(
                                            item.virtual_url?.url,
                                            "youtube"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="video-wrap">
                                      <button
                                        onClick={() =>
                                          handleShow(
                                            item.virtual_url?.url,
                                            "custom_video"
                                          )
                                        }
                                      >
                                        <i className="icon-play"></i>
                                      </button>
                                    </div>
                                  ))}

                            {item?.floorplan&&item?.floorplan[0]?.url ? (
                              <SearchResultsFloorplanPopup
                                propertyId={item.id}
                                propertyFloorPlan={item?.floorplan[0]}
                              />
                            ) : null}
                              </div>
                            </div>
                            {item.department === "residential" && (     
                            <div className="right-block .sb-myacc trending-save">
                               <Link to={myaccountURL}>
                              <button>
                                <i className={"icon-heart"} />
                                <span className="span-btn-text d-none d-md-inline-block"></span>
                              </button>
                              </Link>
                            </div>
                            )}
                          </div>
                          {item.status &&
                       item.status !== "For Sale" &&
                       item.status !== "To Let" && (
                          <div className="property-tag">{item.status}</div>
                       )}
                        
                      </div>
                      <div className="property-details">
                        <div className="property-name">
                          <Link to={`/${uriStr}${item?.slug}-${item?.id}/`}>
                            {item?.display_address}
                          </Link>
                        </div>
                        {/* <p>{item?.title}</p> */}
                        <p className="">
                                {item.description &&                        
                         <Content
                         Content={item.description}
                         page="search-result"
                         uriStr={uriStr}
                         hit={item}
                         />
                            }
                                </p>
                        <div className="property-price">
                        {item?.price_qualifier === "POA"
                          ? "€ Price on Application"
                          : item.price > 10000&&item?.search_type !== "lettings"
                          ?
                         `€${parseFloat(item.price).toLocaleString()}`
                          : 
                          item?.search_type === "lettings" &&item.price>100?
                          `€${parseFloat(item.price).toLocaleString()} ${                            
                            item?.price_qualifier
                              ? item?.price_qualifier
                              : ""
                          }`
                          : "€ Price on Application"}
                        </div>
                        <div className="bottom-block">
                          {item.bedroom >= 1 && (
                            <div className="bedroom-count">
                              <i className="icon-bed"></i>
                              {item.bedroom}
                            </div>
                          )}
                          {item.bathroom >= 1 && (
                            <div className="bathroom-count">
                              <i className="icon-tub"></i>
                              {item.bathroom}
                            </div>
                          )}
                          {item.reception >= 1 && (
                            <div className="lounge-count d-none d-xl-flex">
                              <i className="icon-couch"></i>
                              {item.reception}
                            </div>
                          )}
                          {item.floorarea_min>=1 && (
                            <div className="property-area">
                              <i className="icon-cube"></i>
                              {(item.floorarea_min / 10.7639)
                                .toFixed(1)
                                .replace(".0", "")}{" "}
                              {"m"}
                              <sup>2</sup>
                            </div>
                          )}
                          {item.extrasField && item.extrasField.pBERRating && (
                            <div className="property-label">
                              <div className="d-flex justify-content-start align-items-center property-ber-rating">
                                <div className="ber-black">BER</div>
                                <div
                                  className={
                                    "ber-green " +
                                    item.extrasField.pBERRating.toLowerCase()
                                  }
                                >
                                  {item.extrasField.pBERRating}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {/* <div className='property-card'>
                                <div className="property-card-wrapper">
                                    <div className="property-img">
                                        <Link to={`/`}>
                                          <img src={SimilarPropertyImg} alt="property" />
                                          <div className="property-wrapper">
                                              <div className="left-block">
                                                  <div className="image-wrap">
                                                      <i className="icon-camera"></i>
                                                        <div className="image-count">1/{"2"}</div>
                                                  </div>
                                                  <div className="property-view">
                                                      <div className="video-wrap">
                                                          <button>
                                                              <i className="icon-play"></i>
                                                          </button>
                                                      </div>
                                                      <div className="floor-plan">
                                                          <button>
                                                              <i className="icon-floor-plan"></i>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="right-block">
                                                  <button>
                                                      <i className={'icon-heart' } />
                                                      <span className="span-btn-text d-none d-md-inline-block">{'save' }</span>
                                                  </button>
                                              </div>
                                          </div>
                                          <div className="property-tag">{"For sale"}</div>
                                        </Link>
                                    </div>
                                    <div className="property-details">
                                        <div className="property-name">
                                            <Link to={`/`}>
                                                {"test address"}
                                            </Link>
                                        </div>
                                        <p>{"test title"}</p>
                                        <div className="property-price">€{"124562"}</div>
                                        <div className="bottom-block">
                                            <div className="bedroom-count">
                                                <i className="icon-bed"></i>{"2"}
                                            </div>
                                            <div className="bathroom-count">
                                                <i className="icon-tub"></i>{"1"}
                                            </div>
                                            <div className="lounge-count d-none d-xl-flex">
                                                <i className="icon-couch"></i>{"1"}
                                            </div>
                                            <div className="property-area">
                                                <i className="icon-cube"></i>{`${'132'}m`}<sup>2</sup>
                                            </div>
                                            <div className="property-label">
                                                <img src={propertyLabel} alt="label" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </Slider>
          </Container>
        </div>
        {video_type === "youtube" ? (
            <PlayVideo
              isOpen={isPlay}
              videoId={videoid}
              stopPlay={() => {
                setIsPlay(false)
                setVideoType("")
              }}
            />
          ) : (
            <Modal
              show={show}
              fullscreen={fullscreen}
              onHide={() => {
                setShow(false)
                setVideoType("")
              }}
              dialogClassName="modal-fullscreen property-details-modal"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="p-0">
                <iframe src={videoid}></iframe>
              </Modal.Body>
            </Modal>
          )}
      </React.Fragment>
    )
  )
}

export default SimilarPropertiesTeam

import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Office_Page_Url } from "../../Components/common/site/constants"
import { Container, Row, Col } from "react-bootstrap"
import "./TeamDetails.scss"
import Cta from "@Components/Home/Cta/Cta"
import RelatedTeam from "@Components/StaticPageTwo/RelatedTeam/RelatedTeam"
import ScrollAnimation from "react-animate-on-scroll"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import { inViewOptions } from "../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import logoBlack from "../../images/logo.svg"
import NoImage from "../../images/no-image.png"
import callicon from "../../images/Phone-call.svg"
import NewsBanner from "@Components/Static/NewsBanner/NewsBanner"
import ContactSidebarNews from "@Components/Static/ContactSidebar/ContactSidebarNews"
import OpinionList from "@Components/Static/OpinionList/OpinionList"
import ReactMarkdown from "react-markdown"
import PlayVideo from "../../Components/PlayVideo/PlayVideo"
import RelatedResearch from "@Components/StaticPageTwo/RelatedResearch/RelatedResearch"
import OurAdvantage from "@Components/StaticPageTwo/OurAdvantage/OurAdvantage"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import SimilarPropertiesTeam from "@Components/PropertyDetails/SimilarProperties/SimilarPropertiesTeam"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../../pages/404"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import { isMobile, isMobileOnly, isTablet } from "react-device-detect"
// import { Document, Page } from 'react-pdf/dist/umd/entry.parcel';
// import { PDFViewer } from 'react-view-pdf';
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const TeamDetails = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }

  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])

  const GET_TEAM_DETAILS = gql`
    query GetTeam($URL: String!) {
      teams(where: { URL: $URL }) {
        id
        imagetransforms
        Name
        Designation
        URL
        Email
        Mobile_No
        Phone
        Image {
          alternativeText
          url
        }
        Content
        offices {
          teams {
            id
            imagetransforms
            Designation
            Name
            Image {
              alternativeText
              url
            }
            Publish
            Phone
            Mobile_No
            URL
            Email
            Video_Url
          }
          Office_Name
          Branch_Location
          URL
        }
        departments {
          Name
        }
        Video_Url
      }
    }
  `
  const { loading, error, data } = useQuery(GET_TEAM_DETAILS, {
    variables: { URL: props.slug },
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  const [rteams, setRteam] = useState([])
  const [isPlay, setIsPlay] = useState(false)
  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  return data?.teams?.length > 0 ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout className="team-page" Alias={"find-a-dng-team-member"}>
                {data?.teams?.map((Page, i) => {
                  var newname = Page.Name.split(" ")
                  const emailName = newname[0]
                  let processedImages = JSON.stringify({})
                  if (Page?.imagetransforms?.Image_Transforms) {
                    processedImages = Page.imagetransforms.Image_Transforms
                  }

                  return (
                    <>
                      <SEO
                        title={
                          Page ? Page?.Name + " | " + Page?.Designation : ""
                        }
                        description={
                          Page
                            ? "Get to know about " +
                              Page?.Name +
                              " here. Contact one of our estate agents for assistance in finding the right property for you."
                            : ""
                        }
                        image={Page?.Image?.url ? Page.Image.url : null}
                      />
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template team-page`,
                        }}
                      />
                      <Breadcrumbs
                        Page={Page?.Name}
                        class="static"
                        alias={"find-a-dng-team-member"}
                        type="details-page"
                      />

                      <div className="static-block">
                        <Container>
                          <Row>
                            <Col xl="7" className="col-xxl-8 col-custom-8">
                              <h1>{Page.Name}</h1>
                              {Page.Designation && (
                                <div className="designation">
                                  {Page.Designation}
                                </div>
                              )}
                              {Page.offices?.length > 0 ? (
                                <div className="offices">
                                  <i className="icon-building"></i>{" "}
                                  {Page.offices.map((list, i) => (
                                    <span>
                                      <Link
                                to={Office_Page_Url?.alias+"/"+list.URL + "/"}>
                                      {list.Office_Name}
                                      </Link>
                                      {i === Page.offices?.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  ))}
                                </div>
                              ) : null}
                              {Page.Email || Page.Mobile_No ? (
                                <div className="dealer-cta d-xl-flex bottom-fixed-btn">
                                  {Page.Email && (
                                    <div className="btn-wrap">
                                      <a
                                        href="javascript:;"
                                        onClick={() => handleShow()}
                                        className="btn"
                                      >
                                        Email {emailName}
                                      </a>
                                    </div>
                                  )}

                                  <Modal
                                    show={show}
                                    centered={true}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                  >
                                    <Modal.Header
                                      closeButton
                                      className="contact-close-btn"
                                    >
                                      <Modal.Title className="w-100">
                                        Email to {Page.Name}
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="form">
                                      <TeamForm
                                        toemail={Page.Email}
                                        officeNumber={Page?.Phone}
                                        teamMember={Page?.Name}
                                        branchName={Page.offices[0]}
                                      />
                                    </Modal.Body>
                                  </Modal>
                                  {Page.Phone && (
                                    <div className="btn-wrap">
                                      <a
                                        href={"tel:" + Page.Phone}
                                        className="btn outline-btn call"
                                      >
                                        {isMobileOnly
                                          ? "Call"
                                          : isTablet
                                          ? `call ${Page.Phone}`
                                          : Page.Phone}
                                      </a>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                              <motion.div className="ContentBlock">
                                <ReactMarkdown
                                  source={Page.Content}
                                  allowDangerousHtml
                                />
                              </motion.div>
                            </Col>
                            <Col xl="5" className="col-xxl-4 col-custom-4">
                              <picture className="img-zoom">
                                {Page?.Image?.url ? (

                                  // <ImageTransform
                                  //   imagesources={Page?.Image?.url}
                                  //   renderer="srcSet"
                                  //   imagename="team.Image.detail"
                                  //   attr={{
                                  //     alt: Page.Image?.alternativeText
                                  //       ? Page.Image?.alternativeText
                                  //       : Page?.Name + " - DNG Estate Agents",
                                  //     className: "",
                                  //   }}
                                  //   imagetransformresult={processedImages}
                                  //   id={Page.id}
                                  // ></ImageTransform>
                                  <img src={Page?.Image?.url} alt={Page.Image?.alternativeText
                                        ? Page.Image?.alternativeText
                                        : Page?.Name + " - DNG Estate Agents"}/>
                                ) : (
                                  <img
                                    src={NoImage}
                                    alt={Page.Image?.alternativeText}
                                  />
                                )}
                                {/* <source media="(min-width:1800px)" srcSet={Page?.Image?.url} />
                                <source media="(min-width:992px)" srcSet={Page?.Image?.url} />
                                <source media="(min-width:768px)" srcSet={Page?.Image?.url} />
                                <img src={Page?.Image?.url} alt="" /> */}
                                {Page.Video_Url && (
                                  <button
                                    type="button"
                                    className="play-btn"
                                    onClick={() => {
                                      setIsPlay(true)
                                    }}
                                  >
                                    <i className="icon-play"></i>
                                  </button>
                                )}
                              </picture>

                              {/* <img className="right-img-view" src={Page?.Image?.url} alt={Page?.Image?.alternativeText} /> */}
                              <PlayVideo
                                isOpen={isPlay}
                                stopPlay={setIsPlay}
                                videoId={Page.Video_Url}
                                isAutoPlay={1}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </>
                  )
                })}
                <SimilarPropertiesTeam Email={data?.teams[0]?.Email} />

                <RelatedTeam
                  isPage="team"
                  teams={data?.teams[0]?.offices[0]?.teams?.filter(
                    item => item.Name !== data?.teams[0]?.Name
                  )}
                />

                <Cta static="true" />
                <OurAdvantage static="true" />

                {/* <SubscribeForm />
                <RelatedResearch CurrentNews={data?.researchs[0]?.Title}/> */}
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
}

export default TeamDetails
